html, body {
  height: 100%;
  margin: 0;
}

body {
  font: 10pt Graphik, sans-serif;
}

.ol-zoom button {
  font: 20pt Graphik, sans-serif !important;
}

.ol-zoom .ol-zoom-in, .ol-zoom .ol-zoom-out {
  z-index: 200;
}

.map {
  width: 100%;
  height: 100%;
}

.ol-control {
  background: none !important;
}

.layer-switcher button {
  display: none !important;
}

.layer-switcher ul, .ol-zoom {
  flex-direction: column;
  display: flex;
}

.layer-switcher {
  right: 10px !important;
}

.ol-zoom, .layer-switcher {
  top: auto !important;
  bottom: .5em !important;
}

.layer-switcher ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

.layer-switcher label, .ol-zoom button {
  margin: 0 !important;
}

.ol-control button, .layer-switcher li label {
  min-width: 40px;
  padding: 5px;
  color: #000 !important;
  text-align: center !important;
  background: #f0f3f3 !important;
  border: 1px solid #3d57cc !important;
  border-radius: 0 !important;
  height: 40px !important;
}

.layer-switcher li input[type="checkbox"] {
  display: none;
}

.layer-switcher li input[type="checkbox"]:checked + label {
  font-weight: bold;
  color: #fff !important;
  background: #3d57cc !important;
}

.layer-switcher li label {
  justify-content: center;
  align-items: center;
  margin: 5px;
  display: flex;
}

#logo {
  width: 50px;
  position: absolute;
  top: 5px;
  left: 5px;
}

#logo img {
  width: 100%;
}

#compass {
  position: absolute;
  top: 10px;
  right: 10px;
}

#compass img {
  width: 25px;
}

#legend-container {
  z-index: 999;
  text-align: center;
  background-color: #fff;
  flex-direction: column;
  padding: .75em;
  display: flex;
  position: fixed;
  top: .5em;
  left: 50%;
  transform: translateX(-50%);
}

#legend-container .arrow {
  width: 150px;
}

.legend-row {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.legend-entry {
  padding: .5em;
  display: flex;
}

.legend-entry:last-child {
  padding-right: 0;
}

.legend-text {
  padding: 0 .5em;
}

.ol-attribution {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: .5em;
}

.ol-attribution ul {
  text-align: center;
}

@media (width >= 568px) {
  #logo {
    width: 70px;
  }

  .layer-switcher ul, .ol-zoom {
    flex-direction: row-reverse;
  }

  .ol-control button, .layer-switcher li label {
    padding: 10px;
  }
}

#logo, #compass, #legend img, #legend p {
  z-index: 1;
}

div.ol-rotate {
  display: none;
}

@font-face {
  font-family: Druk;
  font-weight: bold;
  src: url("Druk-Bold-Web.122006f2.woff") format("woff");
}

@font-face {
  font-family: Graphik;
  font-weight: normal;
  src: url("Graphik-Regular-Web.a78a8c05.woff") format("woff");
}

@font-face {
  font-family: Graphik;
  font-weight: bold;
  src: url("Graphik-Semibold-Web.84a3ae1e.woff") format("woff");
}

#tfm-sidebar {
  z-index: 999;
  background: #fff;
  border: 0 solid #6a6a71;
  padding: 0 30px;
  position: fixed;
  overflow-y: auto;
}

#tfm-sidebar button {
  color: #fff;
  border: 0;
  margin: 10px 0;
  padding: 15px;
  font: bold 14px Graphik, sans-serif;
}

#tfm-sidebar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#tfm-sidebar::-webkit-scrollbar-thumb {
  background-color: #00000080;
  border-radius: 4px;
  box-shadow: 0 0 1px #ffffff80;
}

#tfm-sidebar img, #tfm-sidebar button {
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
}

#tfm-sidebar.closed {
  visibility: hidden;
}

#tfm-sidebar.open {
  visibility: visible;
}

#tfm-sidebar #sidebar-content {
  margin-bottom: 5em;
  font: 12pt Graphik, sans-serif;
}

@media only screen and (width >= 501px) {
  #tfm-sidebar {
    transition: width .5s;
  }

  #tfm-sidebar.open {
    border-left-width: 4px;
    width: 40vw;
    max-width: 300px;
    height: 100vh;
    right: 0;
  }

  #tfm-sidebar.closed {
    width: 0;
  }

  #legend-container .arrow.mobile {
    display: none;
  }
}

@media only screen and (width <= 500px) {
  #tfm-sidebar {
    background: linear-gradient(0deg, #000 0%, #fff 2em);
    transition: height .5s;
  }

  #tfm-sidebar.open {
    border-top-width: 4px;
    width: 100vw;
    max-width: calc(100% - 60px);
    height: 70vh;
    bottom: 0;
  }

  #tfm-sidebar.closed {
    height: 0;
  }

  #legend-container {
    background: none;
    padding: 0;
  }

  .legend-text {
    white-space: nowrap;
  }

  .legend-entry {
    display: none;
  }

  #legend-container .arrow.mobile {
    width: 20px;
    margin-left: 2em;
    margin-right: 1em;
    transform: rotate(90deg);
  }

  #legend-container .arrow.desktop {
    display: none;
  }
}

#tfm-sidebar #sidebar-close-button {
  cursor: pointer;
  color: #000;
  font-size: 20pt;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
}
/*# sourceMappingURL=index.04878030.css.map */
